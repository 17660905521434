<template lang="pug">
  .top-bar.filters.h-100
    .row.align-items-center.m-0
      .filter.col
        p.filter-title {{ $t(`company_system.shop_settings.filters.prefectures`) }}
        AppDropdown.prefectures(
          :loading="prefecturesLoading"
          checkbox
          batch-select
          multiple
          allow-empty
          :value="filters.prefectures"
          :items="translatedPrefectures"
          @select="applyFilter('prefectures', $event)"
        )
      .filter.col
        p.filter-title {{ $t("company_system.shop_settings.filters.inventory_groups") }}
        AppDropdown.inventory_groups(
          :loading="inventoryGroupsLoading"
          checkbox
          batch-select
          multiple
          allow-empty
          :value="filters.inventoryGroups"
          :items="inventoryGroups"
          @select="applyFilter('inventoryGroups', $event)"
        )
      .filter.col
        .search.w-100
          AppSearch.w-100(
            :value="filters.searchValue"
            @update="applyFilter('searchValue', $event)"
          )
    .filter-actions.d-flex.justify-content-between.align-items-center.w-100
      .add-new
        AppAddNewButton(
          title="company_system.shop_settings.buttons.add_new"
          @click="$emit('add-new')"
        )
      .download
        AppDownloadButton(disabled)
</template>

<script>
  // misc
  import { map } from "lodash-es"
  import { extractTranslatedAttribute } from "@/helpers/common"

  export default {
    components: {
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppSearch: () => import("@/components/elements/AppSearch"),
      AppDownloadButton: () => import("@/components/elements/AppButton/WithIcon/Download"),
      AppAddNewButton: () => import("@/components/elements/AppButton/WithIcon/AddNew")
    },

    props: {
      inventoryGroups: {
        type: Array,
        default: () => new Array()
      },
      prefectures: {
        type: Array,
        default: () => new Array()
      },
      filters: {
        type: Object,
        default: () => new Object()
      },
      inventoryGroupsLoading: {
        type: Boolean,
        default: true
      },
      prefecturesLoading: {
        type: Boolean,
        default: true
      }
    },

    methods: {
      applyFilter(itemType, items) {
        const filters = { ...this.filters, [itemType]: items }
        this.$emit("change-filters", filters)
      }
    },

    computed: {
      translatedPrefectures() {
        return map(this.prefectures, prefecture => {
          return {
            id: prefecture.id,
            name: extractTranslatedAttribute(prefecture, "name"),
            rawName: prefecture.en_name.toLowerCase().replace(" prefecture")
          }
        })
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"

  .top-bar.filters
    padding: 0

    .filter
      +filter-title
      .filter-title
        margin-bottom: 0
        margin-left: 0

      min-width: 210px
      max-width: 25%

      &:first-child
        padding-left: 0

      ::v-deep
        .app-select
          padding-left: 0

      .search
        padding-top: 20px

      &-actions
        margin-top: 32px
</style>
